import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { lighten, styled } from '@mui/material/styles';
import { Theme as ThemeMui5 } from '@mui/material/styles/createTheme';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { DividerMui5 } from 'components/atoms/Divider';
import NextLinkMui5 from 'components/atoms/NextLink';
import { StyledButtonMui5 } from 'components/atoms/StyledButton';
import Image from 'next/image';
import { FC, useState } from 'react';
import { grey } from 'theme/colors';
import { StyledFontAwesomeIconMui5 } from '../../components/atoms/FontAwesomeIcon';
import { Subheader } from './components/common';
import { SearchOptionMedia } from './components/SearchOptionList/SearchOptionCard';
import {
  SearchOptionItem,
  SearchOptionList,
} from './components/SearchOptionList/SearchOptionList';

const Preview = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(-14),
  marginBottom: theme.spacing(-22),
  padding: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(-18),
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(-14),
  },
}));

const PreviewTitle = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    textTransform: 'uppercase',
    fontSize: 120,
    top: '15%',
    left: '50%',
    transform: 'translateX(-50%)',
    fontWeight: 100,
    position: 'absolute',
    color: lighten(theme.palette.primary.main, 0.85),
    letterSpacing: 16,
  },
}));

const Summary = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(2, 10),
  background: theme.palette.common.white,
  borderRadius: 12,
  border: `1px solid ${lighten(theme.palette.primary.light, 0.7)}`,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 4),
  },
}));

const SummaryText = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontWeight: 'bold',
    fontSize: '1.25rem',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
}));

const viewButtonStyles: SxProps<ThemeMui5> = (theme) => ({
  '&.MuiButton-root': {
    margin: theme.spacing(0.5),
    minWidth: 176,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 1, 1),
    },
  },
});

const bodyStyleOptions: SearchOptionItem[] = [
  {
    title: 'SUV',
    query: 'SUV',
    media: SearchOptionMedia.SUV,
  },
  {
    title: 'Truck',
    query: 'Truck',
    media: SearchOptionMedia.Truck,
  },
  {
    title: 'Sedan',
    query: 'Sedan',
    media: SearchOptionMedia.Sedan,
  },
  {
    title: 'Coupe',
    query: 'Coupe',
    media: SearchOptionMedia.Coupe,
  },
  {
    title: 'Minivan',
    query: 'Minivan',
    media: SearchOptionMedia.Minivan,
  },
];

const budgetOptions: SearchOptionItem[] = [
  {
    title: '$301 - $500',
    titleSize: 'lg',
    query: 'minMonthlyPayment=301&maxMonthlyPayment=500',
    media: SearchOptionMedia.Affordable,
  },
  {
    title: '$501 - $750',
    titleSize: 'lg',
    query: 'minMonthlyPayment=501&maxMonthlyPayment=750',
    media: SearchOptionMedia.MidRange,
  },
  {
    title: '$751 - $1000',
    titleSize: 'lg',
    query: 'minMonthlyPayment=751&maxMonthlyPayment=1000',
    media: SearchOptionMedia.UpperRange,
  },
  {
    title: '$1000+',
    titleSize: 'lg',
    query: 'minMonthlyPayment=1000&maxMonthlyPayment=9007199254740991',
    media: SearchOptionMedia.Luxury,
  },
];

// const featureOptions: SearchOptionItem[] = [
//   {
//     title: 'Apple CarPlay',
//     query: 'Apple+CarPlay',
//     media: SearchOptionMedia.CarPlay,
//   },
//   {
//     title: 'Keyless Entry',
//     query: 'Keyless+Entry',
//     media: SearchOptionMedia.KeylessEntry,
//   },
//   {
//     title: 'Keyless Start',
//     query: 'Keyless+Start',
//     media: SearchOptionMedia.KeylessStart,
//   },

//   {
//     title: 'Heated Seats',
//     query: 'Heated+Seats',
//     media: SearchOptionMedia.HeatedSeats,
//   },
//   {
//     title: 'Leather Seats',
//     query: 'Leather+Seats',
//     media: SearchOptionMedia.LeatherSeats,
//   },
//   {
//     title: 'Heated Steering Wheel',
//     query: 'Heated+Steering+Wheel',
//     media: SearchOptionMedia.HeatedSteeringWheel,
//   },
//   {
//     title: 'Third Row Seating',
//     query: 'Third+Row+Seating',
//     media: SearchOptionMedia.ThirdRowSeating,
//   },
//   {
//     title: 'Sunroof/Moonroof',
//     query: 'Sunroof/Moonroof',
//     media: SearchOptionMedia.Sunroof,
//   },
// ];

const brandOptions: SearchOptionItem[] = [
  {
    title: 'Acura',
    query: 'Acura',
    media: SearchOptionMedia.Acura,
  },
  {
    title: 'Audi',
    query: 'Audi',
    media: SearchOptionMedia.Audi,
  },
  {
    title: 'Buick',
    query: 'Buick',
    media: SearchOptionMedia.Buick,
  },
  {
    title: 'Cadillac',
    query: 'Cadillac',
    media: SearchOptionMedia.Cadillac,
  },
  {
    title: 'Chevrolet',
    query: 'Chevrolet',
    media: SearchOptionMedia.Chevrolet,
  },
  {
    title: 'Chrysler',
    query: 'Chrysler',
    media: SearchOptionMedia.Chrysler,
  },
  {
    title: 'Dodge',
    query: 'Dodge',
    media: SearchOptionMedia.Dodge,
    hideTitle: true,
  },
  {
    title: 'Ford',
    query: 'Ford',
    media: SearchOptionMedia.Ford,
    hideTitle: true,
  },
  {
    title: 'GMC',
    query: 'GMC',
    media: SearchOptionMedia.GMC,
    hideTitle: true,
  },
  {
    title: 'Honda',
    query: 'Honda',
    media: SearchOptionMedia.Honda,
  },
  {
    title: 'Hyundai',
    query: 'Hyundai',
    media: SearchOptionMedia.Hyundai,
  },
  {
    title: 'Jeep',
    query: 'Jeep',
    media: SearchOptionMedia.Jeep,
    hideTitle: true,
  },
  {
    title: 'KIA',
    query: 'Kia',
    media: SearchOptionMedia.KIA,
    hideTitle: true,
  },
  {
    title: 'Lincoln',
    query: 'Lincoln',
    media: SearchOptionMedia.Lincoln,
  },
  {
    title: 'Mazda',
    query: 'Mazda',
    media: SearchOptionMedia.Mazda,
  },
  {
    title: 'Mercedes-Benz',
    query: 'Mercedes-Benz',
    media: SearchOptionMedia.Mercedes,
  },
  {
    title: 'Mitsubishi',
    query: 'Mitsubishi',
    media: SearchOptionMedia.Mitsubishi,
  },
  {
    title: 'Nissan',
    query: 'Nissan',
    media: SearchOptionMedia.Nissan,
    hideTitle: true,
  },
  {
    title: 'RAM',
    query: 'Ram',
    media: SearchOptionMedia.RAM,
  },
  {
    title: 'Toyota',
    query: 'Toyota',
    media: SearchOptionMedia.Toyota,
  },
  {
    title: 'Volkswagen',
    query: 'Volkswagen',
    media: SearchOptionMedia.Volkswagen,
  },
  {
    title: 'Volvo',
    query: 'Volvo',
    media: SearchOptionMedia.Volvo,
    hideTitle: true,
  },
  {
    title: 'BMW',
    query: 'BMW',
    media: SearchOptionMedia.BMW,
  },
  {
    title: 'Lexus',
    query: 'Lexus',
    media: SearchOptionMedia.Lexus,
  },
  {
    title: 'INFINITI',
    query: 'INFINITI',
    media: SearchOptionMedia.Infiniti,
    hideTitle: true,
  },
];

const PopularSearchOptions: FC = () => {
  const [selectedBodyStyle, setSelectedBodyStyle] = useState(
    new Set<string>([bodyStyleOptions[0].title]),
  );
  const [selectedBudget, setSelectedBudget] = useState(new Set<string>());
  // const [selectedFeature, setSelectedFeature] = useState(new Set<string>());
  const [selectedBrand, setSelectedBrand] = useState(new Set<string>());

  const buildSearchUrl = () => {
    let url = '';
    const urlParts = [];
    if (selectedBodyStyle.size > 0) {
      urlParts.push(
        mapSelectedOptionsToQueries(selectedBodyStyle, bodyStyleOptions)
          .map((bodyStyleQuery) => `body_style=${bodyStyleQuery}`)
          .join('&'),
      );
    }
    if (selectedBudget.size > 0) {
      urlParts.push(
        mapSelectedOptionsToQueries(selectedBudget, budgetOptions).join('&'),
      );
    }
    // if (selectedFeature.size > 0) {
    //   urlParts.push(
    //     mapSelectedOptionsToQueries(selectedFeature, featureOptions)
    //       .map((featureQuery) => `feature=${featureQuery}`)
    //       .join('&'),
    //   );
    // }
    if (selectedBrand.size > 0) {
      urlParts.push(
        mapSelectedOptionsToQueries(selectedBrand, brandOptions)
          .map((brandQuery) => `make=${brandQuery}`)
          .join('&'),
      );
    }
    if (urlParts.length) {
      url = `?${urlParts.join('&')}`;
    }

    return url;
  };

  const mapSelectedOptionsToQueries = (
    selectedOptions: Set<string>,
    options: SearchOptionItem[],
  ) => {
    return [...selectedOptions].map(
      (selectedOption) =>
        options.find((option) => option.title === selectedOption)?.query,
    );
  };

  const buildFullImagePath = (bodyStyle: string) => {
    return `/img/body_types/${bodyStyle.toLowerCase()}-full.webp`;
  };

  const bodyStyle = [...selectedBodyStyle].pop();

  return (
    <Box position={'relative'}>
      <Box color={grey[700]} mb={-1}>
        <Subheader variant='h2' color='textSecondary' align='center'>
          Search for popular in-demand cars
        </Subheader>
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Typography variant='h3' align='center' gutterBottom>
          Drive away with your dream car
        </Typography>
      </Box>
      <Box sx={{ display: { md: 'none', xs: 'block' } }}>
        <Typography variant='h4' align='center' gutterBottom>
          Drive away with your dream car
        </Typography>
      </Box>
      <DividerMui5 />
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid container spacing={0}>
          <Grid item lg md>
            <Box mt={-4}>
              <SearchOptionList
                selectedOptions={selectedBodyStyle}
                onSelectedOptionsChange={(options) => {
                  if (options.size > 0) {
                    setSelectedBodyStyle(options);
                  }
                }}
                searchOptions={bodyStyleOptions}
                title='Body styles'
              />
            </Box>
          </Grid>
          <Grid item lg={8} md={7}>
            <Preview>
              <PreviewTitle variant='h2'>{bodyStyle}</PreviewTitle>
              <Image
                src={buildFullImagePath(bodyStyle)}
                alt='car'
                width={1280}
                height={960}
                // hack to fix next14 image
                style={{
                  width: '-webkit-fill-available',
                  height: 'auto',
                  position: 'relative',
                }}
              />
            </Preview>
            {/* <SearchOptionList
              selectedOptions={selectedFeature}
              onSelectedOptionsChange={(options) => {
                setSelectedFeature(options);
              }}
              searchOptions={featureOptions}
              title='Popular features'
              orientation='horizontal'
              multiselect
            /> */}
            <SearchOptionList
              selectedOptions={selectedBrand}
              onSelectedOptionsChange={(options) => {
                setSelectedBrand(options);
              }}
              searchOptions={brandOptions.sort((a, b) =>
                a.title.localeCompare(b.title),
              )}
              title='Popular brands'
              orientation='horizontal'
            />
          </Grid>
          <Grid item lg md>
            <Box display='flex' justifyContent='flex-end' mt={-4}>
              <SearchOptionList
                selectedOptions={selectedBudget}
                onSelectedOptionsChange={(options) => {
                  setSelectedBudget(options);
                }}
                searchOptions={budgetOptions}
                title='Monthly budget'
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: { md: 'none', xs: 'block' } }}>
        <SearchOptionList
          selectedOptions={selectedBodyStyle}
          onSelectedOptionsChange={(options) => {
            if (options.size > 0) {
              setSelectedBodyStyle(options);
            }
          }}
          searchOptions={bodyStyleOptions}
          title='Body styles'
          orientation='horizontal'
          fullWidth
        />
        <SearchOptionList
          selectedOptions={selectedBudget}
          onSelectedOptionsChange={(options) => {
            setSelectedBudget(options);
          }}
          searchOptions={budgetOptions}
          title='Your monthly budget'
          orientation='horizontal'
          fullWidth
        />
        {/* <SearchOptionList
          selectedOptions={selectedFeature}
          onSelectedOptionsChange={(options) => {
            setSelectedFeature(options);
          }}
          searchOptions={featureOptions}
          title='Popular features'
          orientation='horizontal'
          multiselect
          fullWidth
        /> */}
        <SearchOptionList
          selectedOptions={selectedBrand}
          onSelectedOptionsChange={(options) => {
            setSelectedBrand(options);
          }}
          searchOptions={brandOptions.sort((a, b) =>
            a.title.localeCompare(b.title),
          )}
          title='Popular brands'
          orientation='horizontal'
          fullWidth
        />
      </Box>
      <Summary>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <Typography variant='body2' color='textSecondary'>
                Selected options
              </Typography>
              <SummaryText variant='body1'>
                {[
                  ...selectedBodyStyle,
                  ...selectedBudget,
                  ...selectedBrand,
                  // ...selectedFeature,
                ].join(', ')}
              </SummaryText>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Actions>
              <StyledButtonMui5
                component={NextLinkMui5}
                href='/deals'
                variant='outlined'
                color='primary'
                disableElevation
                endIcon={
                  <StyledFontAwesomeIconMui5 icon={faChevronRight} size='xs' />
                }
                sx={viewButtonStyles}
              >
                View all options
              </StyledButtonMui5>
              <StyledButtonMui5
                component={NextLinkMui5}
                href={`/deals${buildSearchUrl()}`}
                variant='contained'
                color='primary'
                disableElevation
                endIcon={
                  <StyledFontAwesomeIconMui5 icon={faChevronRight} size='xs' />
                }
                sx={viewButtonStyles}
              >
                View selection
              </StyledButtonMui5>
            </Actions>
          </Grid>
        </Grid>
      </Summary>
    </Box>
  );
};

export default PopularSearchOptions;
