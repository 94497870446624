import { FC } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StyledFontAwesomeIconMui5 } from '../../../../components/atoms/FontAwesomeIcon';
import { YouTubeEmbed } from 'react-social-media-embed';
import { useSwiperReactive } from '../MediaMentions/hooks/useSwiperReactive';
import { StandardLonghandProperties } from 'csstype';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { PlatformIcon } from '../Testimonials/constants';
import { DividerLeftMui5 } from '../../../../components/atoms/Divider';

type MediaItemProps = {
  name: string;
  description: string;
  video: string;
  platform: PlatformIcon;
  index: number;
};

const MediaWrapper = styled(Paper)(() => ({
  width: '210px',
  height: '374px',
  overflow: 'hidden',
  borderRadius: '20px',
}));

const MediaIcon = styled('div')(({ theme }) => {
  const iconSize: StandardLonghandProperties['height'] = '12.27px';
  const contentWidth: StandardLonghandProperties['width'] = '18px';
  return {
    height: `${theme.typography.body2.lineHeight}em`,
    paddingRight: contentWidth,
    position: 'relative',
    '& svg': {
      position: 'absolute',
      left: `calc(8.26px + ${contentWidth} / 2)`,
      width: iconSize,
      height: iconSize,
      transform: 'translateX(-50%)',
      paddingTop: `calc(calc(${theme.typography.body2.lineHeight}em - ${iconSize}) / 2)`,
    },
  };
});

const MediaContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'relativeIndex',
})<{
  isActive: boolean;
  relativeIndex: number;
}>(({ theme, isActive, relativeIndex }) => {
  let offset: StandardLonghandProperties['left'] = '0';
  if (relativeIndex < 0) {
    offset = theme.spacing(-4);
  } else if (relativeIndex > 0) {
    offset = theme.spacing(4);
  }
  return {
    height: 'fit-content',
    width: 'min-content',
    position: 'relative',
    transform: `translateX(${offset})`,
    ...(!isActive && {
      pointerEvents: 'none',
      filter: 'grayscale(0.5) contrast(50%)',
      opacity: 0.5,
    }),
    transition: theme.transitions.create(['filter', 'transform', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  };
});

export const MediaItem: FC<MediaItemProps> = ({
  name,
  description,
  video,
  platform,
  index,
}) => {
  const swiper = useSwiperReactive();
  const isActive = swiper?.realIndex === index;

  let relativeIndex = index - swiper?.realIndex;
  if (relativeIndex > Math.floor(swiper?.slides.length / 2)) {
    relativeIndex = relativeIndex - swiper?.slides.length;
  } else if (relativeIndex < -Math.floor(swiper?.slides.length / 2)) {
    relativeIndex = relativeIndex + swiper?.slides.length;
  }

  let mediaElement: JSX.Element = null;
  if (platform === PlatformIcon.Youtube) {
    mediaElement = (
      <YouTubeEmbed
        url={video}
        width='210px'
        height='374px'
        placeholderSpinnerDisabled={true}
      />
    );
  }

  return (
    <MediaContainer isActive={isActive} relativeIndex={relativeIndex}>
      <MediaWrapper elevation={4}>{mediaElement}</MediaWrapper>
      <Box mt={1}>
        <Box
          display='flex'
          flexWrap='nowrap'
          justifyContent='space-between'
          alignItems='baseline'
        >
          <Typography variant='body1' sx={{ position: 'relative' }}>
            <b>{name}</b>
          </Typography>
          <MediaIcon>
            <Typography variant='body2' color='textSecondary' textAlign='right'>
              5 <StyledFontAwesomeIconMui5 icon={faStar} color='#F22F2F' />
            </Typography>
          </MediaIcon>
        </Box>
        <DividerLeftMui5
          sx={({ spacing }) => ({
            margin: spacing(1, 0),
            width: '20px',
          })}
        />
        <Typography variant='body2' color='textSecondary'>
          {description}
        </Typography>
      </Box>
    </MediaContainer>
  );
};
