import { BannerProps } from './types';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DividerMui5 } from 'components/atoms/Divider';
import { TopSearchBar } from '../../../../components/organisms/TopSearch';

const StyledContainer = styled(Container)(() => ({
  '&.MuiContainer-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  '&.MuiTypography-root': {
    fontSize: '3rem',
    paddingTop: theme.spacing(8),
    marginBottom: '0.35em',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
      marginBottom: '0',
    },
  },
}));

const SubTitle = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#fff',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  color: theme.palette.common.white,
  marginTop: theme.spacing(-12),
  background: 'url(/img/hero-banner.webp)',
  backgroundSize: 'cover',
  backgroundPosition: 'center bottom',
  height: 560,
  [theme.breakpoints.down('md')]: {
    height: 700,
    imageRendering: 'crisp-edges',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(/img/hero-banner-md.webp)',
  },
  [theme.breakpoints.down('sm')]: {
    height: 750,
    imageRendering: 'crisp-edges',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(/img/hero-banner-sm.webp)',
  },
}));

// eslint-disable-next-line
const FromContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 4),
  marginTop: theme.spacing(4),
  background: theme.palette.common.white,
  borderRadius: 25,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

export default function SearchBar({
  onSubmit,
  title = 'Drive away with your dream car',
  variant = 'h1',
}: BannerProps): JSX.Element {
  return (
    <Wrapper className='js-hero-image'>
      <StyledContainer maxWidth={'md'}>
        <Title color='inherit' variant={variant} align='center'>
          {title}
        </Title>
        <DividerMui5 />
        <SubTitle color='inherit' align='center' variant='h2'>
          Vehicle Leasing and Financing Simplified
        </SubTitle>
        <TopSearchBar onSimpleSubmit={onSubmit} />
      </StyledContainer>
    </Wrapper>
  );
}
