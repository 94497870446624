import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DividerMui5 } from '../../../../components/atoms/Divider';

export const Caption: FC = () => {
  return (
    <Box mt={6.5}>
      <Typography variant='h4' component='h2' align='center' gutterBottom>
        Straight From the Driver’s Seat
      </Typography>
      <Typography
        variant='body1'
        color='textSecondary'
        textTransform='uppercase'
        fontWeight='600'
        align='center'
      >
        We’ve helped thousands drive off happy. Ready to be next?
      </Typography>
      <DividerMui5
        sx={({ spacing }) => ({ margin: spacing(2, 'auto', 4, 'auto') })}
      />
    </Box>
  );
};
