export const BLOGS_LIST: Array<{
  image: string;
  url: string;
}> = [
  {
    image: '/img/testimonials/blogs/AP.png',
    url: 'https://apnews.com/press-release/kisspr/california-94e6fbf9bae02ff9f4b9f803aaf88fb0',
  },
  {
    image: '/img/testimonials/blogs/LAWeekly.png',
    url: 'https://www.laweekly.com/why-autobandit-is-the-future-of-car-leasing-for-digital-first-consumers/',
  },
  {
    image: '/img/testimonials/blogs/USReporter.png',
    url: 'https://usreporter.com/autobandit-launches-comprehensive-online-platform/',
  },
  {
    image: '/img/testimonials/blogs/AppleNewsPlus.png',
    url: 'https://apple.news/AQZtMbk4tRUOt-7rx0v6NXQ',
  },
  {
    image: '/img/testimonials/blogs/BusinessInsider.png',
    url: 'https://markets.businessinsider.com/news/stocks/introducing-autobandit-the-online-car-leasing-and-financing-platform-that-changes-the-game-in-car-leasing-1034294187',
  },
];
